

import React from 'react';
import { Container, Row, Col } from "react-bootstrap";
import styled from 'styled-components';
import { Title, Section, Box, Button, Span, Text } from "../../components/Core";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

import { FaArrowRight } from "react-icons/fa";
import example from "../../assets/image/jpeg/examples.jpg";
 
// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';
import { device, breakpoints } from "../../utils";
import Newarrow from '../../assets/image/jpeg/enter.svg';



const Bookbutton = styled.button`
font-size: 16px;
padding: 12px 34px;
    background-color: #006b7b;
    display: block;
    color: #fff;
    border-radius: 32px;
    transition: .5s;
    border: none;
   

  @media screen and (max-width: 767px) {
    background-color: #006b7b;
    display: inline-block;
    padding: 12px 34px;
    font-size: 14px;
  }
`;






const Arrow = styled.div`


width: 1.8em;
margin-left: 0.8em;
opacity: 0.6;

`;



const Iwrap = styled.div`

display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
margin-bottom: 1em;
-webkit-box-align: center;
-webkit-align-items: center;
-ms-flex-align: center;
align-items: center;


@media screen and (max-width: 767px)
{
    margin-bottom: 8px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

@media screen and (max-width: 991px)
 {
    margin-bottom: 8px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
}


`;



const Iline = styled.div`

width: 6em;
height: 1px;
margin-right: 1em;
background-color: rgb(171, 142, 102);

@media screen and (max-width: 991px)
 {
  width: 3em;
}

`;

const Itext = styled.div`
  margin-top: 0px;
  margin-bottom: 5px;
  color: #1a1a1a;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;

  span{
    color:#00aec7;
  }

  @media screen and (max-width: 991px) {
    font-size: 12px;
  }
`;









const SecondText = styled.h3`

transform: translate3d(0%, 0px, 0px) scale3d(1, 1, 1) rotateX(
  0deg) rotateY(
  0deg) rotateZ(
  0deg) skew(
  0deg, 
  0deg);
      transform-style: preserve-3d;

      font-size: 2.2em;
      font-smooth: always;
      margin-bottom: 0.4em;
     
      color: #1a1a1a;
 
      font-weight: 100;
      letter-spacing: 2px;
      @media screen and (max-width: 767px)
     {
          margin-bottom: 24px;
          font-size: 16px;
          text-align:center;
      }

`;
const Thirdtext = styled.div`

opacity: 1;
transform: translate3d(0%, 0px, 0px) scale3d(1, 1, 1) rotateX(
0deg) rotateY(
0deg) rotateZ(
0deg) skew(
0deg, 
0deg);
transform-style: preserve-3d;
font-family: halyard-display, sans-serif;

width: 90%;
color: rgba(255, 255, 255, 0.7);


color: #fff;
font-size: 1.6em;
line-height: 1.5;


@media screen and (max-width: 767px)
{
     margin-bottom: 24px;
     font-size: 22px;
     text-align:center;
     width: 100%;
 }

`;
const HeroT = styled.h2`
  letter-spacing: 0px;
  font-size: 18px;
  font-weight: 800;
  line-height: 20px;

  color: black;
  margin-bottom: 30px;

  @media ${device.sm} {
    font-size: 66px;
    line-height: 70px;
  }

  @media ${device.lg} {
    font-size: 30px;
    line-height: 40px;
  }

  @media ${device.xl} {
    font-size: 30px;
    line-height: 40px;
  }
`;

const Buttonnew = styled.button `
color: #222;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #efe8de;
  border: none;
  padding: 1rem 4rem;
  font-weight: 600;
  font-family: termina, sans-serif;
  text-transform: uppercase;
  font-size: 0.7rem;
  position: relative;
  border-radius: 100px;
  z-index: 1;
  margin-top:20px;
  margin-right: 1rem;
  cursor: pointer;
  outline: none;
  transform-style: preserve-3d;
  transition: 0.2s ease-out;

  &:hover {
    &.main span {
      color: #fff;
      mix-blend-mode: difference;
    }
    &.alt span {
      mix-blend-mode: difference;
      color: white;
    }
  }

  &.main {
    background: #00afc9;
    color: #efe8de;
    margin: 0 auto;
  }

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 100px;
    z-index: 2;
    box-shadow: -5px -6px 10px #efe8de;
    filter: brightness(1.2);
    transition: 0.3s ease-out;
    pointer-events: none;
  }

  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 100px;
    z-index: 2;
    box-shadow: 5px 10px 15px #efe8de;
    filter: brightness(0.8);
    transition: 0.3s ease-out;
    pointer-events: none;
  }

  &:active {
    &.alt {
      &::after {
        box-shadow: inset 4px 4px 8px #efe8de;
      }

      &::before {
        box-shadow: inset -2px -2px 8px #efe8de;
      }
    }
    &.main {
      &::after {
        box-shadow: 2px 5px 6px #efe8de;
      }

      &::before {
        box-shadow: inset -2px -2px 8px #222;
      }
    }
  }
`

export default function MesoTfaq({setIsModalOpen}) {
    return (
        <Section py="4" id="faq" bg="#f7f7fb" className="">
      <Container className="pb-lg-5 mb pt-lg-5">
        <Row className="justify-content-center">
          <Col lg="12">
   
            <Iwrap>


<Itext as="h2">Questions about <span color="00aec7">Mesotherapy for hair</span></Itext>
</Iwrap>
      <SecondText>
      Frequently Asked Questions

      </SecondText>
         
          </Col>
        </Row>


        <Row className="">
       
       



        <Col
     
     >
           <Accordion allowZeroExpanded >
<AccordionItem>
    <AccordionItemHeading>
         <AccordionItemButton className="text-[14px] pl-2">
        How is Mesotherapy carried out?
        </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
        <p>
        At Dermamina London, the appointment will begin with a thorough consultation. The treatment will be carried out right after, once the hair and scalp has been assessed. 
 
 Mesotherapy injections last around 10 minutes. It is carried out on the scalp to deeply hydrate and stimulate hair regrowth. 
  
 Patient is able to go about their day as normal as the treatment does not cause any undesirable side effects and needed recovery time. 
 

        </p>
    </AccordionItemPanel>
</AccordionItem> 




</Accordion>    <br />
         


           <Accordion allowZeroExpanded >
<AccordionItem>
    <AccordionItemHeading>
         <AccordionItemButton className="text-[14px] pl-2">
        Mesotherapy hair results. How many Mesotherapy sessions are needed?        </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
        <p>
        For optimal results, a minimum of 8 intensive sessions, alternating between mesotherapy and PRP are recommended. After the second treatment, hair loss usually stops, and two to three months later, new hair growth typically begins. It is advised to have maintenance treatments every three months for long-lasting benefits.
 
 First three sessions of mesotherapy need to be a month apart. If combining with PRP, there needs to be a 2-week gap between each. 
 


        </p>
    </AccordionItemPanel>
</AccordionItem>




</Accordion>  

<br />


      
<Accordion allowZeroExpanded >

<AccordionItem>
    <AccordionItemHeading>
         <AccordionItemButton className="text-[14px] pl-2">
        Does Mesotherapy hair treatment hurt?
        </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
        <p>
        The needles used for injecting the scalp are among the thinnest, making the procedure virtually painless. Local anaesthetic cream is also available upon request.
 

        </p>
    </AccordionItemPanel>
</AccordionItem>



</Accordion>  


<br />


      
<Accordion allowZeroExpanded >

<AccordionItem>
    <AccordionItemHeading>
         <AccordionItemButton className="text-[14px] pl-2">
        Mesotherapy recovery time
        </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
        <p>
     
After the treatment, you can immediately resume your normal activities; no recovery period is required. Following each treatment for 24 hours, it is advisable to let the scalp heal by avoiding washing your hair or putting any pressure (no head massage, hair dressing) directly on the scalp or hair. In order to avoid infection, please keep the region free of perspiration and dirt. During the consultation, additional post-treatment advice will be explained.

        </p>
    </AccordionItemPanel>
</AccordionItem>



</Accordion>    
         
<br />
        
<Accordion allowZeroExpanded >
        <AccordionItem>
    <AccordionItemHeading>
         <AccordionItemButton className="text-[14px] pl-2">
        Is Mesotherapy harmful?       </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
        <p>
        Mesotherapy is a cutting-edge option that is minimally invasive and safe, especially compared to invasive and surgical hair transplants. 
 
 It can be therapeutic and harmless to the scalp and surrounding tissues too.
 
      </p>
    </AccordionItemPanel>
</AccordionItem>





</Accordion>    
<br />
<Accordion allowZeroExpanded >
 
<AccordionItem>
    <AccordionItemHeading>
         <AccordionItemButton className="text-[14px] pl-2">
        How to prepare for your treatment    </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
        <p>
        Ensure you eat a meal and drink plenty of water prior to your treatment. 
 
 Attend the appointment with hair washed, ensuring there are no products, hair systems, oils etc. Treatment may not take place if you attend with hair not washed and with products. 
 

        </p>
    </AccordionItemPanel>
</AccordionItem>




</Accordion>    

<br />

<Accordion allowZeroExpanded>
  <AccordionItem>
    <AccordionItemHeading>
       <AccordionItemButton className="text-[14px] pl-2">
        Who is a suitable candidate for Mesotherapy hair treatment?
      </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
      <p>
        Mesotherapy is suitable for individuals experiencing hair thinning, early stages of hair loss, or seeking improved scalp health. However, it is not ideal for those with advanced hair loss or complete baldness. A consultation at Dermamina will determine if Mesotherapy is the right treatment for you.
      </p>
    </AccordionItemPanel>
  </AccordionItem>
</Accordion>
<br />
<Accordion allowZeroExpanded>
  <AccordionItem>
    <AccordionItemHeading>
       <AccordionItemButton className="text-[14px] pl-2">
        What are the benefits of Mesotherapy for hair?
      </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
      <p>
        Mesotherapy nourishes the scalp, stimulates hair follicles, reduces hair thinning, and improves hair strength and density. It also boosts blood circulation, providing an ideal environment for hair regrowth. With regular sessions, many patients notice thicker and healthier hair.
      </p>
    </AccordionItemPanel>
  </AccordionItem>
</Accordion>
<br />
<Accordion allowZeroExpanded>
  <AccordionItem>
    <AccordionItemHeading>
       <AccordionItemButton className="text-[14px] pl-2">
        Are there any side effects of Mesotherapy?
      </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
      <p>
        Side effects are minimal and rare but may include slight redness, swelling, or tenderness at the injection site. These effects are temporary and usually subside within a few hours. Our specialists will ensure a safe and comfortable procedure.
      </p>
    </AccordionItemPanel>
  </AccordionItem>
</Accordion>
<br />
<Accordion allowZeroExpanded>
  <AccordionItem>
    <AccordionItemHeading>
       <AccordionItemButton className="text-[14px] pl-2">
        Can Mesotherapy be combined with other treatments?
      </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
      <p>
        Yes, Mesotherapy can be combined with treatments like PRP for enhanced results. Alternating between Mesotherapy and PRP can optimize hair regrowth and scalp health. Your treatment plan will be tailored to your needs during the consultation.
      </p>
    </AccordionItemPanel>
  </AccordionItem>
</Accordion>
<br />



        
</Col>



        </Row>

 


<br /> <br />
        <div onClick={() => setIsModalOpen(true)}
             activeClassName="active">
          <Bookbutton>Book appointment</Bookbutton>
        </div>


      </Container>
        </Section>


    );
}








